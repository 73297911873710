@mixin font-face($font-name, $file-name) {
  @font-face {
    font-family: quote($font-name);
    src: url($file-name + '.eot');
    src: url($file-name + '.eot?#iefix') format('embedded-opentype'),
    url($file-name + '.woff') format('woff'),
    url($file-name + '.woff2') format('woff2'),
    url($file-name + '.ttf') format('truetype'),
    url($file-name + '.svg##{$font-name}') format('svg');
  }
}

@include font-face('Montserrat-Medium', '../../fonts/Montserrat-Medium');
@include font-face('Montserrat-Bold', '../../fonts/Montserrat-Bold');
@include font-face('Oswald-Bold', '../../fonts/Oswald-Bold');