.btn {
  @include commonBtnStyles;
  @include btn-primary;

  &--border {
    @include btn-border;
  }
  &--second {
    @include btn-secondary;
  }
}

.ant {
  &-btn {
    font-weight: 500;
    line-height: 8px;
    text-transform: uppercase;

    &-lg {
      height: 45px;
    }

    &-circle.ant-btn-lg {
      height: 45px;
      width: 45px;
    }

    &-round {
      display: inline-flex;
      align-items: center;

      &.ant-btn-lg {
        font-size: 12px;
        padding: 10px 40px;
        height: 45px;
      }
    }

    &-primary {
      font-size: 14px;
      font-weight: 700;
      text-transform: uppercase;
      color: #29263A;
      border: none !important;
      background: linear-gradient(180deg, #FFC702 0%, #FF8B20 100%);
      /* button shadows */
      box-shadow: 0px 10px 26px rgba(0, 0, 0, 0.1), inset 0px 2px 0px rgba(255, 255, 255, 0.4), inset 0px -4px 0px rgba(0, 0, 0, 0.25);
      border-radius: 84px;
      //@include btn-primary;
      //transition: none !important;

      &:hover {
        color: #29263A;
        background: linear-gradient(180deg, #FFC702 0%, #FF8B20 100%);

        //background: #FFA510 !important;
        box-shadow: none;
      }
    }

    &-secondary {
      @include btn-secondary;
    }

    &-tertiary {
      @include btn-tertiary;
    }

    &-default {
      font-size: 14px;
      font-weight: 700;
      text-transform: uppercase;
      color: #29263A;
      border: none;
      background: linear-gradient(180deg, #4EFFC3 0%, #25CE76 100%);
      box-shadow: 0px 10px 26px rgba(0, 0, 0, 0.1), inset 0px -4px 0px rgba(0, 0, 0, 0.25);
      
      &:hover {
        color: #29263A;
        background: linear-gradient(180deg, #4EFFC3 0%, #25CE76 100%);
        box-shadow: none;
      }
    }

    &-with-icon {
      @include with-icon;
    }

    &-loading {
      @include loading;
    }
  }
}
