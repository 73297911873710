.h-100per {
  height: 100%;
}

.w-100per {
  width: 100%;
}

.padV10 {
  padding: 10px 0;
}

.padG10 {
  padding: 0 10px;
}

.mrV10 {
  margin: 10px 0;
}

.mrG10 {
  margin: 0 10px;
}

.fx-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.fx-top-center {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.fx-bottom-center {
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.fxDirCol {
  flex-direction: column;
}

.fxDirRow {
  flex-direction: row;
}

.alItemsCenter {
  align-items: center;
}

.text-center {
  text-align: center;
}

.bgw {
  background: $alwaysWhite;
}

.bR10 {
  border-radius: 10px;
}

.container {
  width: 1366px;
  max-width: 100%;
  padding: 0 30px;
  margin: 0 auto;

  @include media('<568px') {
    padding: 0 10px;
  }
}
.custom-section {
  margin-top: 70px;

  @include media("<568px") {
    margin-top: 30px;
  }

  &__title {

    &.ant-typography {
      text-align: left;
      margin-bottom: 30px;
      font-size: 35px;

      @include media("<568px") {
        font-size: 21px;
      }
    }
  }

  &--no-indent {
    margin-top: 0;
  }
}
.get-started {
  padding-top: 15px;
  text-align: center;

  .btn {
    min-width: 210px;
  }
}

.burger-menu {
  border: none;
  cursor: pointer;
  padding: 0;
  background: none;
  display: none;
  width: 35px;
  height: 35px;
  img {
    width: 100%;
    height: 100%;
  }
  @include media('<=860px') {
    display: block;
    margin-left: auto;
  }

}

.close-modal-button {
  position: absolute;
  right: 15px;
  top: 15px;
  width: 18px;
  height: 18px;
  padding: 0;
  cursor: pointer;
  background: url("../../assets/images/close-white.svg") no-repeat center / contain;
  border: none;
}
.main-wrap {
  padding-bottom: 60px;
  flex: 1;

  &--inner {
    padding: 60px 0;

    @include media("<568px") {
      padding: 30px 0;
    }

    .commissions {
      margin-top: 0;
      margin-bottom: 40px;
    }
  }
}