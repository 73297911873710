

.testimonials {
  align-content: center;
  margin: 40px 0 100px 0;

  .ant-card-head {
    border-radius: 10px 10px 0 0;
    color: #000;
  }

}
.testimonial-card {
  margin: 15px 20px;
  &.ant-card {
    background: $darkBox;
    color: #000;
    &.ant-card-bordered {
      border-color: $darkInput;
    }
  }
}
