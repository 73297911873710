.footer {
  left: 0;
  bottom: 0;
  height: 82px;
  width: 100%;
  &__wrapper {
    max-width: 1366px;
    margin: 0 auto;
    padding: 26px 64px 25px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include media('<768px') {
      flex-direction: column;
      text-align: center;
    }
  }

  &__info {
    text-transform: uppercase;
    font-weight: 900;
    font-size: 14px;
    line-height: 16px;
  }

  &__menu {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;
    gap: 15px;

    @include media('<768px') {
      flex-direction: column;
      margin-top: 15px;
    }
    a {
      color: #000;

      &.active {
        color: $primaryColor;
      }
    }
  }
}